import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'chat',
    title: 'chat',
    translate: 'MENU.CHAT',
    type: 'item',
    icon: 'message-circle',
    url: 'chat'
  }
]
