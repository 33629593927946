import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { menu } from "app/menu/menu";
import { NotificationService } from "app/services/toast.service";
@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _notificationService: NotificationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = localStorage.getItem("token");
    const role = JSON.parse(localStorage.getItem("userData"))?.accountType;
    
    if (token) {
      if ( role === "COACH") {
        return true;
      } else {
        this._notificationService.showError(
          "vous n'êtes pas autorisé à y accéder.",
          "Désolé"
        );
        this._router.navigate(["/"]);
        return false;
      }
    } else {
      this._router.navigate(["/login"]);
      return false;
    }
  }
}